import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import DashboardAdmin from './Dashboards/Admin/DashboardAdmin';
import DashboardParent from './Dashboards/Parent/DashboardParent';
import DashboardStudent from './Dashboards/Student/DashboardStudent';
import DashboardTutor from './Dashboards/Tutor/DashboardTutor';

export const Dashboard = () => {
    const navigate = useNavigate();
    const userInfo = useGetUserInfo();
    const userRole = userInfo ? userInfo.role : '';

    useEffect(() => {
        const isLoggedIn = Cookies.get('loggedIn');
        if (!isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className="dashboard">

            <div className="content">
                {/* Dashboard content based on user role */}
                {userRole === 'admin' && <DashboardAdmin />}
                {userRole === 'student' && <DashboardStudent />}
                {userRole === 'parent' && <DashboardParent />}
                {userRole === 'tutor' && <DashboardTutor />}
            </div>
        </div>
    );
};

export default Dashboard;

