import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../config/firebase-config';
import { useGetEvents } from '../../../../hooks/useGetEvents';
import { useUpdateEvents } from '../../../../hooks/useUpdateEvents';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

const ProgressComponent = () => {
    const [students, setStudents] = useState([]);
    const [children, setChildren] = useState([]);
    const [sessionEvents, setSessionEvents] = useState([]);
    const [editingEventId, setEditingEventId] = useState(null);
    const [editingStudentId, setEditingStudentId] = useState(null);
    const [hwDetails, setHwDetails] = useState('');
    const [progressDetails, setProgressDetails] = useState('');
    const [editingHomework, setEditingHomework] = useState(false);
    const [editingProgress, setEditingProgress] = useState(false);
    const events = useGetEvents();
    const tutor = useGetUserInfo();
    const { updateEvent } = useUpdateEvents();

    const fetchStudents = async () => {
        const studentsCollection = collection(db, 'Users');
        const studentsQuery = query(studentsCollection, where('role', '==', 'student'));
        const studentsSnapshot = await getDocs(studentsQuery);
        const studentsList = studentsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
        }));
        setStudents(studentsList);
    };

    const fetchChildren = async () => {
        const childrenCollection = collection(db, 'Users');
        const childrenQuery = query(childrenCollection, where('role', '==', 'parent'));
        const childrenSnapshot = await getDocs(childrenQuery);
        const childrenList = childrenSnapshot.docs.flatMap(doc => {
            const childInfo = doc.data().childInfo;
            if (childInfo) {
                return Object.keys(childInfo).map(childId => ({
                    id: childId,
                    parentName: doc.data().name,
                    ...childInfo[childId],
                }));
            }
            return [];
        });
        setChildren(childrenList);
    };

    const fetchSessionEvents = () => {
        const sessionEventsList = events.filter(event => event.type === 'session' && event.tutorName === tutor.name);
        sessionEventsList.sort((a, b) => b.start.seconds - a.start.seconds);
        setSessionEvents(sessionEventsList);
    };

    useEffect(() => {
        fetchStudents();
        fetchChildren();
    }, []);

    useEffect(() => {
        if (events.length > 0) {
            fetchSessionEvents();
        }
    }, [events]);

    const toggleDropdown = (id, type) => {
        if (type === 'student') {
            setStudents(prevStudents =>
                prevStudents.map(student =>
                    student.id === id ? { ...student, expanded: !student.expanded } : student
                )
            );
        } else if (type === 'child') {
            setChildren(prevChildren =>
                prevChildren.map(child =>
                    child.id === id ? { ...child, expanded: !child.expanded } : child
                )
            );
        }
    };

    const handleEditHomework = (eventId, studentId, currentHw) => {
        setEditingEventId(eventId);
        setEditingStudentId(studentId);
        setHwDetails(currentHw);
        setEditingHomework(true);
    };

    const handleEditProgress = (eventId, studentId, currentProgress) => {
        setEditingEventId(eventId);
        setEditingStudentId(studentId);
        setProgressDetails(currentProgress);
        setEditingProgress(true);
    };

    const handleSaveHomework = async () => {
        const eventToUpdate = sessionEvents.find(event => event.id === editingEventId);
        if (eventToUpdate) {
            const updatedStudentInfo = {
                ...eventToUpdate.studentInfo,
                [editingStudentId]: {
                    ...eventToUpdate.studentInfo[editingStudentId],
                    hw: hwDetails,
                },
            };
            const updatedEventData = {
                ...eventToUpdate,
                studentInfo: updatedStudentInfo,
            };
            await updateEvent(editingEventId, updatedEventData);
            setEditingEventId(null);
            setEditingStudentId(null);
            setEditingHomework(false);
        }
    };

    const handleSaveProgress = async () => {
        const eventToUpdate = sessionEvents.find(event => event.id === editingEventId);
        if (eventToUpdate) {
            const updatedStudentInfo = {
                ...eventToUpdate.studentInfo,
                [editingStudentId]: {
                    ...eventToUpdate.studentInfo[editingStudentId],
                    progress: progressDetails,
                },
            };
            const updatedEventData = {
                ...eventToUpdate,
                studentInfo: updatedStudentInfo,
            };
            await updateEvent(editingEventId, updatedEventData);
            setEditingEventId(null);
            setEditingStudentId(null);
            setEditingProgress(false);
        }
    };

    return (
        <div className="progress">
            <h2>Students</h2>
            {students.length > 0 ? (
                <ul>
                    {students.map(student => (
                        <li key={student.id}>
                            <div
                                onClick={() => toggleDropdown(student.id, 'student')}
                                style={{ cursor: 'pointer' }}
                            >
                                {student.name}
                            </div>
                            {student.expanded && (
                                <ul>
                                    {sessionEvents.map(event =>
                                        event.studentInfo && Object.keys(event.studentInfo).map(studentId => {
                                            const studentInfo = event.studentInfo[studentId];
                                            return studentInfo.studentName === student.name && (
                                                <li key={event.id}>
                                                    Event Start: {new Date(event.start.seconds * 1000).toLocaleString()}<br />
                                                    Event End: {new Date(event.end.seconds * 1000).toLocaleString()}<br />
                                                    Tutor: {event.tutor}<br />
                                                    Subjects: {studentInfo.subjects.join(', ')}<br />
                                                    Topics: {studentInfo.topics.join(', ')}<br />
                                                    <div>
                                                        {editingEventId === event.id && editingStudentId === studentId ? (
                                                            <div>
                                                                <textarea
                                                                    value={editingHomework ? hwDetails : progressDetails}
                                                                    onChange={(e) => editingHomework ? setHwDetails(e.target.value) : setProgressDetails(e.target.value)}
                                                                />
                                                                <button onClick={editingHomework ? handleSaveHomework : handleSaveProgress}>
                                                                    {editingHomework ? 'Save Homework' : 'Save Progress'}
                                                                </button>
                                                                <button onClick={() => {
                                                                    setEditingEventId(null);
                                                                    setEditingStudentId(null);
                                                                    setEditingHomework(false); // Reset editing state
                                                                }}>Cancel</button>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                Homework Assigned: {studentInfo.hw}
                                                                <button onClick={() => handleEditHomework(event.id, studentId, studentInfo.hw)}>
                                                                    Edit Homework Assigned
                                                                </button><br />
                                                                Progress: {studentInfo.progress}
                                                                <button onClick={() => handleEditProgress(event.id, studentId, studentInfo.progress)}>
                                                                    Edit Progress
                                                                </button><br />
                                                            </>
                                                        )}
                                                    </div>
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No students found.</p>
            )}

            <h2>Children</h2>
            {children.length > 0 ? (
                <ul>
                    {children.map(child => (
                        <li key={child.id}>
                            <div
                                onClick={() => toggleDropdown(child.id, 'child')}
                                style={{ cursor: 'pointer' }}
                            >
                                {child.name}
                            </div>
                            {child.expanded && (
                                <ul>
                                    {sessionEvents.map(event =>
                                        event.studentInfo && Object.keys(event.studentInfo).map(studentId => {
                                            const studentInfo = event.studentInfo[studentId];
                                            return studentInfo.studentName === child.name && (
                                                <li key={event.id}>
                                                    Event Start: {new Date(event.start.seconds * 1000).toLocaleString()}<br />
                                                    Event End: {new Date(event.end.seconds * 1000).toLocaleString()}<br />
                                                    Tutor: {event.tutor}<br />
                                                    Subjects: {studentInfo.subjects.join(', ')}<br />
                                                    Topics: {studentInfo.topics.join(', ')}<br />
                                                    <div>
                                                        {editingEventId === event.id && editingStudentId === studentId ? (
                                                            <div>
                                                                <textarea
                                                                    value={editingHomework ? hwDetails : progressDetails}
                                                                    onChange={(e) => editingHomework ? setHwDetails(e.target.value) : setProgressDetails(e.target.value)}
                                                                />
                                                                <button onClick={editingHomework ? handleSaveHomework : handleSaveProgress}>
                                                                    {editingHomework ? 'Save Homework' : 'Save Progress'}
                                                                </button>
                                                                <button onClick={() => {
                                                                    setEditingEventId(null);
                                                                    setEditingStudentId(null);
                                                                    setEditingHomework(false); // Reset editing state
                                                                }}>Cancel</button>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                Homework Assigned: {studentInfo.hw}
                                                                <button onClick={() => handleEditHomework(event.id, studentId, studentInfo.hw)}>
                                                                    Edit Homework Assigned
                                                                </button><br />
                                                                Progress: {studentInfo.progress}
                                                                <button onClick={() => handleEditProgress(event.id, studentId, studentInfo.progress)}>
                                                                    Edit Progress
                                                                </button><br />
                                                            </>
                                                        )}
                                                    </div>
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No children found.</p>
            )}
        </div>
    );
};
    
export default ProgressComponent;