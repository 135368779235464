import React, { useState } from 'react';
import { auth } from '../../../../config/firebase-config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAddUserInfo } from '../../../../hooks/useAddUserInfo';

const AddUsersComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('student');
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [year, setYear] = useState('');
    const [subjects, setSubjects] = useState(['']); // Initialize with one empty subject
    const [numOfChildren, setNumOfChildren] = useState('');
    const [children, setChildren] = useState([{ name: '', grade: '', subjects: [''] }]); // Initialize with one empty child

    const { addUserInfo } = useAddUserInfo();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Validation to ensure at least one subject or one child
            if ((role === 'student' && subjects.length === 0) || (role === 'parent' && children.length === 0)) {
                setError('At least one subject or one child is required.');
                return;
            }

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userInfo = {
                uid: user.uid,
                email,
                role,
                name,
                phoneNumber
            };

            if (role === 'student') {
                userInfo.year = year;
                userInfo.subjects = subjects;
            }

            if (role === 'tutor') {
                userInfo.subjects = subjects;
            }

            if (role === 'parent') {
                userInfo.numOfChildren = numOfChildren;
                userInfo.childInfo = children;
            }

            await addUserInfo(userInfo);

            // Reset form fields after successful submission
            setEmail('');
            setPassword('');
            setRole('student');
            setName('');
            setPhoneNumber('');
            setYear('');
            setSubjects(['']); // Reset subjects to one empty subject
            setNumOfChildren('');
            setChildren([{ name: '', grade: '', subjects: [''] }]); // Reset children to one empty child

            alert('User added successfully!');
        } catch (err) {
            setError(err.message);
        }
    };

    const handleNumOfChildrenChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setNumOfChildren(count);

        const updatedChildren = [];
        for (let i = 0; i < count; i++) {
            updatedChildren.push({ name: '', grade: '', subjects: [] });
        }
        setChildren(updatedChildren);
    };

    const handleChildChange = (index, field, value) => {
        const updatedChildren = [...children];
        updatedChildren[index][field] = value;
        setChildren(updatedChildren);
    };

    const handleSubjectChange = (index, subjectIndex, value) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects[subjectIndex] = value;
        setChildren(updatedChildren);
    };

    const addSubjectField = (index) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects.push('');
        setChildren(updatedChildren);
    };

    const removeSubjectField = (index, subjectIndex) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects.splice(subjectIndex, 1);
        setChildren(updatedChildren);
    };

    const handleAddSubject = () => {
        setSubjects([...subjects, '']);
    };

    const removeSubject = (index) => {
        const updatedSubjects = [...subjects];
        updatedSubjects.splice(index, 1);
        setSubjects(updatedSubjects);
    };

    const handleSubjectInputChange = (index, value) => {
        const updatedSubjects = [...subjects];
        updatedSubjects[index] = value;
        setSubjects(updatedSubjects);
    };

    return (
        <div className="AddUsersComponent">
            <h2>Add New User</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Phone Number:</label>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Role:</label>
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="student">Student</option>
                        <option value="parent">Parent</option>
                        <option value="tutor">Tutor</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>
                {role === 'student' && (
                    <>
                        <div>
                            <label>Year:</label>
                            <input
                                type="number"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Subjects:</label>
                            {subjects.map((subject, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        value={subject}
                                        onChange={(e) => handleSubjectInputChange(index, e.target.value)}
                                        required
                                    />
                                    <button type="button" onClick={() => removeSubject(index)}>
                                        Remove Subject
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={handleAddSubject}>
                                + Add Subject
                            </button>
                        </div>
                    </>
                )}
                {role === 'tutor' && (
                    <>
                        <div>
                            <label>Subjects:</label>
                            {subjects.map((subject, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        value={subject}
                                        onChange={(e) => handleSubjectInputChange(index, e.target.value)}
                                        required
                                    />
                                    <button type="button" onClick={() => removeSubject(index)}>
                                        Remove Subject
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={handleAddSubject}>
                                + Add Subject
                            </button>
                        </div>
                    </>
                )}
                {role === 'parent' && (
                    <>
                        <div>
                            <label>Number of Children:</label>
                            <input
                                type="number"
                                value={numOfChildren}
                                onChange={handleNumOfChildrenChange}
                                required
                            />
                        </div>
                        {children.map((child, index) => (
                            <div key={index}>
                                <label>Child {index + 1} Name:</label>
                                <input
                                    type="text"
                                    value={child.name}
                                    onChange={(e) => handleChildChange(index, 'name', e.target.value)}
                                    required
                                />
                                <label>Child {index + 1} Grade Level:</label>
                                <input
                                    type="number"
                                    value={child.grade}
                                    onChange={(e) => handleChildChange(index, 'grade', e.target.value)}
                                    required
                                />
                                <label>Child {index + 1} Subjects:</label>
                                {child.subjects.map((subject, subjectIndex) => (
                                    <div key={subjectIndex}>
                                        <input
                                            type="text"
                                            value={subject}
                                            onChange={(e) => handleSubjectChange(index, subjectIndex, e.target.value)}
                                            required
                                        />
                                        <button type="button" onClick={() => removeSubjectField(index, subjectIndex)}>
                                            Remove Subject
                                        </button>
                                    </div>
                                ))}
                                <button type="button" onClick={() => addSubjectField(index)}>
                                    + Add Subject
                                </button>
                            </div>
                        ))}
                    </>
                )}
                <button type="submit">Add User</button>
            </form>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default AddUsersComponent;
