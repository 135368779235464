import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firebase-config";

export const useAddUserInfo = () => {
  const userCollectionRef = collection(db, "Users");
  
  const addUserInfo = async (userInfo) => {
    // Define the common fields
    const commonFields = {
      email: userInfo.email,
      name: userInfo.name,
      role: userInfo.role,
      phoneNumber: userInfo.phoneNumber,
    };

    // Add additional fields based on the user role
    let additionalFields = {};
    if (userInfo.role === 'student') {
      additionalFields = {
        year: userInfo.year,
        subjects: userInfo.subjects,
      };
    } else if (userInfo.role === 'tutor') {
      additionalFields = {
        subjects: userInfo.subjects,
      };
    } else if (userInfo.role === 'parent') {
      additionalFields = {
        numOfChildren: userInfo.numOfChildren,
        childInfo: userInfo.childInfo,
      };
    }

    // Merge the common fields with the additional fields
    const userDocument = {
      ...commonFields,
      ...additionalFields,
    };

    await addDoc(userCollectionRef, userDocument);
  };

  return { addUserInfo };
};
