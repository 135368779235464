import React, { useState } from 'react';
import menuIcon from '../../../../images/menu-icon.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ScheduleComponent from './ScheduleComponent';
import ClientsComponent from './ClientsComponent';
import TutorsComponent from './TutorsComponent';
import AddUsersComponent from './AddUsersComponent';
import ProgressComponent from './ProgressComponent';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

const DashboardAdmin = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState('Dashboard');
    const userInfo=useGetUserInfo();
    const userName = userInfo ? userInfo.name : '';
    const navigate = useNavigate();

    const handleMenuClick = () => {
        // Toggle sidebar open/close
        setSidebarOpen(!sidebarOpen);
    };

    const handleLogout = () => {
        Cookies.remove('loggedIn');
        Cookies.remove('userEmail');
        navigate('/');
    };

    const handleSidebarItemClick = (item) => {
        setSelectedComponent(item);
    };

    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case 'Schedule':
                return <ScheduleComponent />;
            case 'Clients':
                return <ClientsComponent />;
            case 'Tutors':
                return <TutorsComponent />;
            case 'Progress':
                return <ProgressComponent />;
            case 'AddUsers':
                return <AddUsersComponent />;
            default:
                return null;
        }
    };

    return (
        <div className="Admin">
            <h1>Welcome {userName}</h1>
            {/* Navigation bar */}
            <div className="navbar">
                {/* Menu or category button */}
                <button className="menu" onClick={handleMenuClick}><img src={menuIcon} alt="Menu"></img></button>
                {/* Button to view profile */}
                <button className="logout" onClick={handleLogout}>Logout</button>
            </div>

            {/* Sidebar */}
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <nav>
                    <ul>
                        <li onClick={() => handleSidebarItemClick('Dashboard')}>Dashboard</li>
                        <li onClick={() => handleSidebarItemClick('Schedule')}>Schedule</li>
                        <li onClick={() => handleSidebarItemClick('Progress')}>Progress</li>
                        <li onClick={() => handleSidebarItemClick('Clients')}>Clients</li>
                        <li onClick={() => handleSidebarItemClick('Tutors')}>Tutors</li>
                        <li onClick={() => handleSidebarItemClick('AddUsers')}>Add Users</li>
                    </ul>
                </nav>
            </div>
            <div className="dashboard-content">
                <h2>Dashboard</h2>
                {renderSelectedComponent()}
            </div>
        </div>
    );
};

export default DashboardAdmin;
