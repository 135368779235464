import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';

export const useUpdateUserInfo = () => {
    const userCollectionRef = collection(db, 'Users');

    const updateUserInfo = async (userId, updatedData) => {
        const userRef = doc(userCollectionRef, userId);

        await updateDoc(userRef, updatedData);
    };

    return { updateUserInfo };
};