import React, { useEffect, useState } from 'react';
import { useGetEvents } from '../../../../hooks/useGetEvents';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

const ProgressComponent = () => {
    const [sessionEvents, setSessionEvents] = useState([]);
    const student = useGetUserInfo();
    const events = useGetEvents();

    const fetchSessionEvents = () => {
        const sessionEventsList = events.filter(event => event.type === 'session');
        // Sort session events in reverse chronological order
        sessionEventsList.sort((a, b) => b.start.seconds - a.start.seconds);
        setSessionEvents(sessionEventsList);
    };

    useEffect(() => {
        if (events.length > 0) {
            fetchSessionEvents();
        }
    }, [events]);

    return (
        <div className="progress">
            {sessionEvents.map(event =>
                event.studentInfo && Object.keys(event.studentInfo).map(studentId => {
                    const studentInfo = event.studentInfo[studentId];
                    return studentInfo.studentName === student.name && (
                        <li key={event.id}>
                            Event Start: {new Date(event.start.seconds * 1000).toLocaleString()}<br />
                            Event End: {new Date(event.end.seconds * 1000).toLocaleString()}<br />
                            Tutor: {event.tutor}<br />
                            Subjects: {studentInfo.subjects.join(', ')}<br />
                            Topics: {studentInfo.topics.join(', ')}<br />
                            Homework Assigned: {studentInfo.hw}<br />
                            {/* Display other event information here */}
                        </li>
                    );
                })
            )}
        </div>
    );
};

export default ProgressComponent;
