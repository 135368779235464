import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useGetEvents } from '../../../../hooks/useGetEvents'; // Adjust the path as needed
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

// Configure the localizer with moment.js
const localizer = momentLocalizer(moment);

const ScheduleComponent = () => {
    const events = useGetEvents();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const userInfo = useGetUserInfo();

    useEffect(() => {
        // Transform Firestore events to react-big-calendar event format
        if (events.length > 0 && userInfo && userInfo.childInfo) {
            const transformedEvents = events.filter(event => event.type === 'global' || (event.type === 'session' &&
                Object.values(event.studentInfo).some(map => {
                    for (const key in userInfo.childInfo) {
                        if (userInfo.childInfo.hasOwnProperty(key) && userInfo.childInfo[key].name === map.studentName) {
                            return true; // Found a match, return true
                        }
                    }
                    return false; // No match found
                })))
                .map(event => {
                    let eventDetails = {};
    
                    switch (event.type) {
                        case 'global':
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                                about: event.about,
                            };
                            break;
                        case 'session':
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                                tutorName: event.tutorName,
                                studentInfo: event.studentInfo,
                            };
                            break;
                        default:
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                            };
                    }
    
                    return eventDetails;
                });
            setCalendarEvents(transformedEvents);
        }
    }, [events, userInfo]);
    
    const handleEventClick = event => {
        setSelectedEvent(event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    const renderEventDetails = () => {
        if (!selectedEvent) return null;
    
        let eventDetails = null;
    
        switch (selectedEvent.type) {
            case 'global':
                eventDetails = (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>About</strong>: {selectedEvent.about}</p>
                        <p><strong>Start</strong>: {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End</strong>: {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
                break;
            case 'session':
                eventDetails = (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>Tutor Name:</strong> {selectedEvent.tutorName}</p>
                        <div className="student-info-container">
                            {selectedEvent.studentInfo &&
                                Object.values(selectedEvent.studentInfo)
                                    .filter(info => Object.values(userInfo.childInfo).some(child => child.name === info.studentName))
                                    .map((info, index) => (
                                        <div key={index} className="student-info-box">
                                            <p><strong>Student Name:</strong> {info.studentName}</p>
                                            <p><strong>Progress:</strong> {info.progress}</p>
                                            <p>
                                                <strong>Subjects:</strong>{' '}
                                                {info.subjects.join(', ')}
                                            </p>
                                            <p>
                                                <strong>Topics: </strong>
                                                {info.topics.join(', ')}
                                            </p>
                                        </div>
                                    ))}
                        </div>
                        <p><strong>Start:</strong> {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End:</strong> {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
                break;
            default:
                eventDetails = <h3>{selectedEvent.title}</h3>;
                break;
        }
    
        return (
            <div className="event-details">
                {eventDetails}
            </div>
        );
    };
    
    return (
        <div className="Schedule">
            <h2>Schedule</h2>
            <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, margin: '50px' }}
                onSelectEvent={handleEventClick}
            />

            {selectedEvent && (
                <div className="event-modal">
                    <div className="event-modal-content">
                        <span className="close-modal" onClick={handleCloseModal}>
                            &times;
                        </span>
                        {renderEventDetails()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleComponent;
