import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useGetEvents } from '../../../../hooks/useGetEvents'; // Adjust the path as needed
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

// Configure the localizer with moment.js
const localizer = momentLocalizer(moment);

const ScheduleComponent = () => {
    const events = useGetEvents();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const userInfo = useGetUserInfo(); // Assuming you have a function to get user information

    useEffect(() => {
        // Filter and transform Firestore events to react-big-calendar event format
        if (events.length > 0 && userInfo) {
            const filteredEvents = events.filter(event => {
                return (
                    event.type === 'global' || // Include global events
                    (event.type === 'session' && userInfo.name === event.tutorName) // Include session events where user is tutor
                );
            });

            const transformedEvents = filteredEvents.map(event => ({
                id: event.id,
                title: event.title,
                type: event.type,
                start: new Date(event.start.seconds * 1000),
                end: new Date(event.end.seconds * 1000),
                about: event.about,
                tutorName: event.tutorName,
                studentName: event.studentName,
                studentInfo: event.studentInfo,
                subjects: event.subjects,
            }));

            setCalendarEvents(transformedEvents);
        }
    }, [events, userInfo]);

    const handleEventClick = event => {
        setSelectedEvent(event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    const renderEventDetails = () => {
        if (!selectedEvent) return null;

        switch (selectedEvent.type) {
            case 'global':
                return (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>About</strong>: {selectedEvent.about}</p>
                        <p><strong>Start</strong>: {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End</strong>: {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
            case 'session':
                return (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>Tutor Name:</strong> {selectedEvent.tutorName}</p>
                        <div className="student-info-container">
                            {selectedEvent.studentInfo && Object.values(selectedEvent.studentInfo).map((info, index) => (
                                <div key={index} className="student-info-box">
                                   <p><strong>Student Name:</strong> {info.studentName}</p>
                                    <p><strong>Progress:</strong> {info.progress}</p>
                                    <p>
                                        <strong>Subjects:</strong>{' '}
                                        {info.subjects.join(', ')}
                                    </p>
                                    <p>
                                        <strong>Topics: </strong>
                                        {info.topics.join(', ')}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <p><strong>Start:</strong> {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End:</strong> {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
            default:
                return <h3>{selectedEvent.title}</h3>;
        }
    };

    return (
        <div className="Schedule">
            <h2>Schedule</h2>
            <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, margin: '50px' }}
                onSelectEvent={handleEventClick}
            />

            {selectedEvent && (
                <div className="event-modal">
                    <div className="event-modal-content">
                        <span className="close-modal" onClick={handleCloseModal}>
                            &times;
                        </span>
                        {renderEventDetails()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleComponent;
