import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../../config/firebase-config';
import EditUserInfo from './EditUserInfo';

const ClientsComponent = () => {
    const [clients, setClients] = useState([]);
    const [editingClient, setEditingClient] = useState(null);

    const fetchClients = async () => {
        const clientsCollection = collection(db, 'Users');

        const studentsQuery = query(clientsCollection, where('role', '==', 'student'));
        const studentsSnapshot = await getDocs(studentsQuery);
        const studentsList = studentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            type: 'student',
            editing: false,
            expanded: false,
        }));

        const parentsQuery = query(clientsCollection, where('role', '==', 'parent'));
        const parentsSnapshot = await getDocs(parentsQuery);
        const parentsList = parentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            type: 'parent',
            editing: false,
            expanded: false,
        }));

        const allClients = [...studentsList, ...parentsList];
        setClients(allClients);
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const toggleDropdown = (clientId) => {
        setClients(prevClients =>
            prevClients.map(client =>
                client.id === clientId ? { ...client, expanded: !client.expanded } : { ...client, expanded: false }
            )
        );
    };

    const toggleEditingClient = (clientId) => {
        setEditingClient(clientId === editingClient ? null : clientId);
    };

    const handleClientClick = (e, clientId) => {
        e.stopPropagation();
        toggleDropdown(clientId);
    };

    const handleEditClick = (e, clientId) => {
        e.stopPropagation();
        toggleEditingClient(clientId);
    };

    const renderChildInfo = (childInfo) => {
        const childInfoArray = childInfo ? Object.entries(childInfo) : [];
        return childInfoArray.map(([childId, child], index) => (
            <div key={childId} className="child-info">
                <h3>Child {index + 1}</h3>
                <p><strong>Name:</strong> {child.name}</p>
                <p><strong>Grade:</strong> {child.grade}</p>
                <p><strong>Subjects:</strong></p>
                <ul>
                    {child.subjects.map((subject, subjIndex) => (
                        <li key={subjIndex}>{subject}</li>
                    ))}
                </ul>
            </div>
        ));
    };

    const handleRemoveClient = async (e, clientId) => {
        e.stopPropagation();
        try {
            const clientDocRef = doc(db, 'Users', clientId);
            await deleteDoc(clientDocRef);
            setClients(clients.filter(client => client.id !== clientId));
        } catch (error) {
            console.error("Error removing client: ", error);
        }
    };

    return (
        <div className="clients">
            <h2>Clients</h2>
            {clients.length > 0 ? (
                <ul>
                    {clients.map(client => (
                        <li key={client.id}>
                            <div 
                                onClick={(e) => handleClientClick(e, client.id)} 
                                className="client-name" 
                                role="button" 
                                tabIndex={0}
                                onKeyPress={(e) => e.key === 'Enter' && handleClientClick(e, client.id)}
                            >
                                <strong>Name:</strong> {client.name}
                                <button onClick={(e) => handleEditClick(e, client.id)}>Edit</button>
                                <button onClick={(e) => handleRemoveClient(e, client.id)}>Remove</button>
                            </div>
                            {editingClient === client.id && (
                                <EditUserInfo
                                    user={client}
                                    onCancel={() => toggleEditingClient(client.id)}
                                    onSave={() => {
                                        fetchClients(); // Refresh clients list
                                        toggleEditingClient(client.id);
                                    }}
                                />
                            )}
                            {client.expanded && editingClient !== client.id && (
                                <div className="client-details">
                                    {client.type === 'student' ? (
                                        <>
                                            <p><strong>Email:</strong> {client.email}</p>
                                            <p><strong>Year:</strong> {client.year}</p>
                                            <p><strong>Phone Number:</strong> {client.phoneNumber}</p>
                                            <p><strong>Subjects:</strong></p>
                                            <ul>
                                                {client.subjects.map((subject, index) => (
                                                    <li key={index}>{subject}</li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : (
                                        <>
                                            <p><strong>Email:</strong> {client.email}</p>
                                            <p><strong>Phone Number:</strong> {client.phoneNumber}</p>
                                            <p><strong>Child Info:</strong></p>
                                            {client.childInfo && Object.keys(client.childInfo).length > 0 ? (
                                                renderChildInfo(client.childInfo)
                                            ) : (
                                                <p>No child information available.</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No clients found.</p>
            )}
        </div>
    );
};

export default ClientsComponent;
