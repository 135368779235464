import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../config/firebase-config';
import { deleteDoc, doc } from 'firebase/firestore';
import EditUserInfo from './EditUserInfo';

const TutorsComponent = () => {
    const [tutors, setTutors] = useState([]);
    const [editingTutor, setEditingTutor] = useState(null);

    const fetchTutors = async () => {
        const tutorsCollection = collection(db, 'Users');
        const tutorsQuery = query(tutorsCollection, where('role', '==', 'tutor'));
        const tutorsSnapshot = await getDocs(tutorsQuery);
        const tutorsData = tutorsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            expanded: false, // Initialize expanded state
        }));
        setTutors(tutorsData);
    };

    useEffect(() => {
        fetchTutors();
    }, []);

    const toggleDropdown = (tutorId) => {
        setTutors(prevTutors =>
            prevTutors.map(tutor =>
                tutor.id === tutorId ? { ...tutor, expanded: !tutor.expanded } : { ...tutor, expanded: false }
            )
        );
    };

    const removeTutor = async (tutorId) => {
        try {
            await deleteDoc(doc(db, 'Users', tutorId));
            setTutors(prevTutors => prevTutors.filter(tutor => tutor.id !== tutorId));
        } catch (error) {
            console.error("Error removing tutor: ", error);
        }
    };    

    const toggleEditingTutor = (tutorId) => {
        setEditingTutor(tutorId === editingTutor ? null : tutorId);
    };

    const handleTutorClick = (e, tutorId) => {
        e.stopPropagation();
        toggleDropdown(tutorId);
    };

    const handleEditClick = (e, tutorId) => {
        e.stopPropagation();
        toggleEditingTutor(tutorId);
    };

    return (
        <div className="tutors">
            <h2>Tutors</h2>
            {tutors.length > 0 ? (
                <ul>
                    {tutors.map(tutor => (
                        <li key={tutor.id}>
                            <div 
                                onClick={(e) => handleTutorClick(e, tutor.id)} 
                                className="tutor-name" 
                                role="button" 
                                tabIndex={0}
                                onKeyPress={(e) => e.key === 'Enter' && handleTutorClick(e, tutor.id)}
                            >
                                <strong>Name:</strong> {tutor.name}
                                <button onClick={(e) => handleEditClick(e, tutor.id)}>Edit</button>
                                <button onClick={(e) => {e.stopPropagation(); removeTutor(tutor.id);}}>Remove</button>
                            </div>
                            {editingTutor === tutor.id && (
                                <EditUserInfo
                                    user={tutor}
                                    onCancel={() => toggleEditingTutor(tutor.id)}
                                    onSave={() => {
                                        fetchTutors(); // Refresh tutors list
                                        toggleEditingTutor(tutor.id);
                                    }}
                                />
                            )}
                            {tutor.expanded && editingTutor !== tutor.id && (
                                <div className="tutor-details">
                                    <p><strong>Email:</strong> {tutor.email}</p>
                                    <p><strong>Phone Number:</strong> {tutor.phoneNumber}</p>
                                    <p><strong>Subjects taught:</strong></p>
                                    <ul>
                                        {tutor.subjects.map((subject, index) => (
                                            <li key={index}>{subject}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No tutors found.</p>
            )}
        </div>
    );
};

export default TutorsComponent;
