import React, { useState } from 'react';
import { useUpdateUserInfo } from '../../../../hooks/useUpdateUserInfo';

const EditUserInfo = ({ user, onCancel, onSave }) => {
    const [name, setName] = useState(user.name);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '');
    const [year, setYear] = useState(user.year || '');
    const [subjects, setSubjects] = useState(user.subjects || ['']); 
    const [children, setChildren] = useState(user.childInfo || [{ name: '', grade: '', subjects: [''] }]);
    const [error, setError] = useState('');
    
    const { updateUserInfo } = useUpdateUserInfo();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            if (subjects.length === 0 && user.role === 'student') {
                setError('At least one subject is required.');
                return;
            }

            const updatedData = {
                name,
                phoneNumber,
            };

            if (user.role === 'student') {
                updatedData.year = year;
            }

            if (user.role === 'parent') {
                updatedData.childInfo = children;
            }

            if (user.role !== 'parent'){
                updatedData.subjects=subjects;
            }

            await updateUserInfo(user.id, updatedData);

            alert('User information updated successfully!');
            onSave();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleChildChange = (index, field, value) => {
        const updatedChildren = [...children];
        updatedChildren[index][field] = value;
        setChildren(updatedChildren);
    };

    const handleSubjectChange = (index, subjectIndex, value) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects[subjectIndex] = value;
        setChildren(updatedChildren);
    };

    const addSubjectField = (index) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects.push('');
        setChildren(updatedChildren);
    };

    const removeSubjectField = (index, subjectIndex) => {
        const updatedChildren = [...children];
        updatedChildren[index].subjects.splice(subjectIndex, 1);
        setChildren(updatedChildren);
    };

    const handleAddSubject = () => {
        setSubjects([...subjects, '']);
    };

    const removeSubject = (index) => {
        const updatedSubjects = [...subjects];
        updatedSubjects.splice(index, 1);
        setSubjects(updatedSubjects);
    };

    const handleSubjectInputChange = (index, value) => {
        const updatedSubjects = [...subjects];
        updatedSubjects[index] = value;
        setSubjects(updatedSubjects);
    };

    return (
        <div className="EditUserInfo">
            <h2>Edit User Information</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                {user.role === 'student' && (
                    <>
                        <div>
                            <label>Year:</label>
                            <input
                                type="number"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                required
                            />
                        </div>
                    </>
                )}
                {user.role !== 'parent' &&(
                    <div>
                        <label>Subjects:</label>
                        {subjects.map((subject, index) => (
                            <div key={index}>
                                <input
                                    type="text"
                                    value={subject}
                                    onChange={(e) => handleSubjectInputChange(index, e.target.value)}
                                    required
                                />
                                <button type="button" onClick={() => removeSubject(index)}>
                                    Remove Subject
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddSubject}>
                            + Add Subject
                        </button>
                    </div>
                )}
                {user.role === 'parent' && (
                    <div>
                        <label>Phone Number:</label>
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                )}
                {user.role === 'parent' && children.map((child, index) => (
                    <div key={index}>
                        <h3>Child {index + 1}</h3>
                        <div>
                            <label>Child Name:</label>
                            <input
                                type="text"
                                value={child.name}
                                onChange={(e) => handleChildChange(index, 'name', e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Grade:</label>
                            <input
                                type="number"
                                value={child.grade}
                                onChange={(e) => handleChildChange(index, 'grade', e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Subjects:</label>
                            {child.subjects.map((subject, subjectIndex) => (
                                <div key={subjectIndex}>
                                    <input
                                        type="text"
                                        value={subject}
                                        onChange={(e) => handleSubjectChange(index, subjectIndex, e.target.value)}
                                        required
                                    />
                                    <button type="button" onClick={() => removeSubjectField(index, subjectIndex)}>
                                        Remove Subject
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={() => addSubjectField(index)}>
                                + Add Subject
                            </button>
                        </div>
                    </div>
                ))}
                {error && <p className="error">{error}</p>}
                <button type="submit">Save</button>
                <button type="button" onClick={onCancel}>Cancel</button>
            </form>
        </div>
    );
};

export default EditUserInfo;
