import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Auth from './pages/auth/index';
import {Dashboard} from './pages/Dashboard/index';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' exact element={<Auth/>}></Route>
          <Route path='/dashboard' exact element={<Dashboard/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;