import { useState, useEffect } from 'react';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import Cookies from 'js-cookie';

export const useGetUserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getUserInfo = async () => {
      let unsubscribe;
      try {
        const userCollectionRef = collection(db, 'Users');

        const queryUserInfo = query(userCollectionRef, where('email', '==', Cookies.get('userEmail')));

        unsubscribe = onSnapshot(queryUserInfo, (snapshot) => {
          let userData = null;
          snapshot.forEach((doc) => {
            userData = doc.data();
          });
          setUserInfo(userData);
        });
      } catch (error) {
        console.error('Error fetching user info:', error);
        // Handle error (e.g., show error message, set default user info, etc.)
      }
      return () => unsubscribe();
    };

    getUserInfo();
  }, []);

  return userInfo;
};
