import React, { useEffect, useState } from 'react';
import { useGetEvents } from '../../../../hooks/useGetEvents';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

const ProgressComponent = () => {
    const userInfo = useGetUserInfo();
    const [sessionEvents, setSessionEvents] = useState([]);
    const events = useGetEvents();
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (userInfo && userInfo.childInfo) {
            const childArray = Object.keys(userInfo.childInfo).map(childId => ({
                id: childId,
                name: userInfo.childInfo[childId].name,
                expanded: false,
                ...userInfo.childInfo[childId],
            }));
            setChildren(childArray);
        }
    }, [userInfo]);    

    const fetchSessionEvents = () => {
        const sessionEventsList = events.filter(event => event.type === 'session');
        // Sort session events in reverse chronological order
        sessionEventsList.sort((a, b) => b.start.seconds - a.start.seconds);
        setSessionEvents(sessionEventsList);
    };

    useEffect(() => {
        if (events.length > 0) {
            fetchSessionEvents();
        }
    }, [events]);

    const toggleDropdown = (id) => {
        setChildren(prevChildren =>
            prevChildren.map(child =>
                child.id === id ? { ...child, expanded: !child.expanded } : child
            )
        );
    };

    return (
        <div className="progress">
            <h2>Children</h2>
            {children.length > 0 ? (
                <ul>
                    {children.map(child => (
                        <li key={child.id}>
                            <div
                                onClick={() => toggleDropdown(child.id, 'child')}
                                style={{ cursor: 'pointer' }}
                            >
                                {child.name}
                            </div>
                            {child.expanded && (
                                <ul>
                                    {sessionEvents.map(event =>
                                        event.studentInfo && Object.keys(event.studentInfo).map(studentId => {
                                            const studentInfo = event.studentInfo[studentId];
                                            return studentInfo.studentName === child.name && (
                                                <li key={event.id}>
                                                    Event Start: {new Date(event.start.seconds * 1000).toLocaleString()}<br />
                                                    Event End: {new Date(event.end.seconds * 1000).toLocaleString()}<br />
                                                    Tutor: {event.tutor}<br />
                                                    Subjects: {studentInfo.subjects.join(', ')}<br />
                                                    Topics: {studentInfo.topics.join(', ')}<br />
                                                    Homework Assigned: {studentInfo.hw}<br />
                                                    {/* Display other event information here */}
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No children found.</p>
            )}
        </div>
    );
};

export default ProgressComponent;
