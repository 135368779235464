import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../config/firebase-config";

export const useUpdateEvents = () => {
  const updateEvent = async (eventId, eventData) => {
    const {
      title,
      start,
      end,
      type,
      about,
      studentInfo,
      tutorName,
    } = eventData;

    // Convert 'start' and 'end' to Firestore Timestamps

    let updatedEventDocument = {
      title,
      start: start, // Use the Firestore Timestamp
      end: end, // Use the Firestore Timestamp
      type,
      // Add other fields based on the event type
      ...(type === 'global' && { about }),
      ...(type === 'session' && { tutorName, studentInfo}),
    };

    const eventDocRef = doc(db, "Events", eventId);

    await updateDoc(eventDocRef, updatedEventDocument);
  };

  return { updateEvent };
};
