import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useGetEvents } from '../../../../hooks/useGetEvents'; // Adjust the path as needed
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';

// Configure the localizer with moment.js
const localizer = momentLocalizer(moment);

const ScheduleComponent = () => {
    const events = useGetEvents();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const userInfo=useGetUserInfo();

    useEffect(() => {
        // Transform Firestore events to react-big-calendar event format
        if (events.length > 0 && userInfo) {
            const transformedEvents = events.filter(event => event.type==='global' || (event.type === 'session' && event.studentInfo && 
            Object.values(event.studentInfo).some(map => map.studentName === userInfo.name)))
                .map(event => {
                    let eventDetails = {};
    
                    switch (event.type) {
                        case 'global':
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                                about: event.about,
                            };
                            break;
                        case 'session':
                            console.log(event.studentInfo)
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                                tutorName: event.tutorName,
                                studentInfo: event.studentInfo,
                            };
                            break;
                        default:
                            eventDetails = {
                                id: event.id,
                                title: event.title,
                                type: event.type,
                                start: new Date(event.start.seconds * 1000),
                                end: new Date(event.end.seconds * 1000),
                            };
                    }
    
                    return eventDetails;
                });
            setCalendarEvents(transformedEvents);
        }
    }, [events, userInfo]);
        
    const handleEventClick = event => {
        setSelectedEvent(event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };

    const renderEventDetails = () => {
        if (!selectedEvent) return null;
    
        switch (selectedEvent.type) {
            case 'global':
                return (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>About</strong>: {selectedEvent.about}</p>
                        <p><strong>Start</strong>: {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End</strong>: {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
            case 'session':
                return (
                    <>
                        <h3>{selectedEvent.title}</h3>
                        <p><strong>Tutor Name:</strong> {selectedEvent.tutorName}</p>
                        <div className="student-info-container">
                            {selectedEvent.studentInfo && Object.values(selectedEvent.studentInfo)
                                .filter(info => info.studentName === userInfo.name)
                                .map((info, index) => (
                                    <div key={index} className="student-info-box">
                                        <p><strong>Student Name:</strong> {info.studentName}</p>
                                        <p><strong>Progress:</strong> {info.progress}</p>
                                        <p>
                                            <strong>Subjects:</strong>{' '}
                                            {info.subjects.join(', ')}
                                        </p>
                                        <p>
                                            <strong>Topics: </strong>
                                            {info.topics.join(', ')}
                                        </p>
                                    </div>
                                ))}
                        </div>
                        <p><strong>Start:</strong> {moment(selectedEvent.start).format('LLL')}</p>
                        <p><strong>End:</strong> {moment(selectedEvent.end).format('LLL')}</p>
                    </>
                );
            default:
                return <h3>{selectedEvent.title}</h3>;
        }
    };
            
    return (
        <div className="Schedule">
            <h2>Schedule</h2>
            <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500, margin: '50px' }}
                onSelectEvent={handleEventClick}
            />

            {selectedEvent && (
                <div className="event-modal">
                    <div className="event-modal-content">
                        <span className="close-modal" onClick={handleCloseModal}>
                            &times;
                        </span>
                        {renderEventDetails()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleComponent;
