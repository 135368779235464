import React from 'react';

const AddEventComponent = ({ newEvent, setNewEvent, handleAddEvent, addStudentInfo, studentName, setStudentName, topics, setTopics, subjects, setSubjects, hw, setHw }) => {
    return (
        <div className="add-event-form">
            <label>Event Title:</label>
            <input
                type="text"
                placeholder="Event Title"
                value={newEvent.title}
                onChange={e => setNewEvent({ ...newEvent, title: e.target.value })}
                required
            />
            <label>Start:</label>
            <input
                type="datetime-local"
                placeholder="Start Date and Time"
                value={newEvent.start}
                onChange={e => setNewEvent({ ...newEvent, start: e.target.value })}
                required
            />
            <label>End:</label>
            <input
                type="datetime-local"
                placeholder="End Date and Time"
                value={newEvent.end}
                onChange={e => setNewEvent({ ...newEvent, end: e.target.value })}
                required
            />
            <label>Type:</label>
            <select value={newEvent.type} onChange={e => setNewEvent({ ...newEvent, type: e.target.value })}>
                <option value="">Select Event Type</option>
                <option value="session">Session</option>
                <option value="global">Global</option>
            </select>
            {newEvent.type === 'global' && (
                <>
                    <label>About:</label>
                    <input
                        type="text"
                        placeholder="About (Global)"
                        value={newEvent.about}
                        onChange={e => setNewEvent({ ...newEvent, about: e.target.value })}
                        required
                    />
                </>
            )}
            {newEvent.type === 'session' && (
                <>
                    <label>Tutor Name:</label>
                    <input
                        type="text"
                        placeholder="Tutor Name"
                        value={newEvent.tutorName}
                        onChange={e => setNewEvent({ ...newEvent, tutorName: e.target.value })}
                        required
                    />
                    <label>Student Name:</label>
                    <input
                        type="text"
                        placeholder="Student Name"
                        value={studentName}
                        onChange={e => setStudentName(e.target.value)}
                        required
                    />
                    <label>Topics:</label>
                    <input
                        type="text"
                        placeholder="Topics (comma separated)"
                        value={topics}
                        onChange={e => setTopics(e.target.value)}
                        required
                    />
                    <label>Subjects:</label>
                    <input
                        type="text"
                        placeholder="Subjects (comma separated)"
                        value={subjects}
                        onChange={e => setSubjects(e.target.value)}
                        required
                    />
                    <label>Homework Assigned:</label>
                    <input
                        type="text"
                        placeholder="Homework Assigned"
                        value={hw}
                        onChange={e => setHw(e.target.value)}
                        required
                    />
                    <button type="button" onClick={addStudentInfo}>Add Student</button>
                    <div>
                        {newEvent.studentInfo.map((student, index) => (
                            <div key={index} className="student-info-box">
                                <p>Student Name: {student.studentName}</p>
                                <p>Topics: {student.topics.join(', ')}</p>
                                <p>Subjects: {student.subjects.join(', ')}</p>
                                <p>Homework Assigned: {student.hw}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
            <button onClick={handleAddEvent}>Add Event</button>
        </div>
    );
};

export default AddEventComponent;
