import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useGetEvents } from '../../../../hooks/useGetEvents'; // Adjust the path as needed
import { useAddEvents } from '../../../../hooks/useAddEvents';
import { useUpdateEvents } from '../../../../hooks/useUpdateEvents';
import { db } from '../../../../config/firebase-config';
import { doc, deleteDoc } from 'firebase/firestore';

import AddEventComponent from './AddEventsComponent';
import EditEventComponent from './EditEventsComponent';

const localizer = momentLocalizer(moment);

const ScheduleComponent = () => {
    const { addEvent } = useAddEvents();
    const { updateEvent } = useUpdateEvents();
    const events = useGetEvents();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showAddEventForm, setShowAddEventForm] = useState(false);
    const [showEditEventForm, setShowEditEventForm] = useState(false);
    const [newEvent, setNewEvent] = useState({
        title: '',
        start: '',
        end: '',
        type: '',
        about: '',
        tutorName: '',
        studentInfo: [],
    });
    const [studentName, setStudentName] = useState('');
    const [topics, setTopics] = useState('');
    const [subjects, setSubjects] = useState('');
    const [hw, setHw] = useState('');

    useEffect(() => {
        if (events.length > 0) {
            const transformedEvents = events.map(event => {
                let eventDetails = {};

                switch (event.type) {
                    case 'global':
                        eventDetails = {
                            id: event.id,
                            title: event.title,
                            type: event.type,
                            start: new Date(event.start.seconds * 1000),
                            end: new Date(event.end.seconds * 1000),
                            about: event.about,
                        };
                        break;
                    case 'session':
                        eventDetails = {
                            id: event.id,
                            title: event.title,
                            type: event.type,
                            start: new Date(event.start.seconds * 1000),
                            end: new Date(event.end.seconds * 1000),
                            tutorName: event.tutorName,
                            studentInfo: event.studentInfo,
                        };
                        break;
                    default:
                        eventDetails = {
                            id: event.id,
                            title: event.name,
                            type: event.type,
                            start: new Date(event.start.seconds * 1000),
                            end: new Date(event.end.seconds * 1000),
                        };
                }

                return eventDetails;
            });
            setCalendarEvents(transformedEvents);
        }
    }, [events]);

    const handleEventClick = event => {
        setSelectedEvent(event);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
        setShowEditEventForm(false);
    };

    const handleAddButton = () => {
        setShowAddEventForm(true);
    };

    const handleEditButton = () => {
        setShowEditEventForm(true);
    };

    const handleAddEvent = () => {
        const { title, start, end, type, about, tutorName, studentInfo } = newEvent;

        const eventData = {
            id: calendarEvents.length + 1,
            title,
            start: new Date(start),
            end: new Date(end),
            type,
            ...(type === 'global' && { about }),
            ...(type === 'session' && { tutorName, studentInfo }),
        };

        setCalendarEvents([...calendarEvents, eventData]);
        setShowAddEventForm(false);
        addEvent({
            title,
            start,
            end,
            type,
            about,
            tutorName,
            studentInfo,
        });
        setNewEvent({ title: '', start: '', end: '', type: '', about: '', tutorName: '', studentInfo: [] });
    };

    const handleUpdateEvent = () => {
        const { id, title, start, end, type, about, tutorName, studentInfo } = selectedEvent;

        const updatedEventData = {
            title,
            start: new Date(start),
            end: new Date(end),
            type,
            ...(type === 'global' && { about }),
            ...(type === 'session' && { tutorName, studentInfo }),
        };

        setCalendarEvents(
            calendarEvents.map(event =>
                event.id === id ? { ...event, ...updatedEventData } : event
            )
        );
        setShowEditEventForm(false);
        updateEvent(id, {
            title,
            start,
            end,
            type,
            about,
            tutorName,
            studentInfo,
        });
    };

    const addStudentInfo = () => {
        setNewEvent({
            ...newEvent,
            studentInfo: [
                ...newEvent.studentInfo,
                { studentName, topics: topics.split(','), subjects: subjects.split(',') },
            ],
        });
        setStudentName('');
        setTopics('');
        setSubjects('');
        setHw('');
    };

    const handleRemoveButton = async() => {
        if (selectedEvent) {
            const eventDocRef=doc(db, 'Events', selectedEvent.id);
            deleteDoc(eventDocRef);
            setCalendarEvents(calendarEvents.filter(event => event.id !== selectedEvent.id)); // Update the local state
            setSelectedEvent(null);
        }
    };

    return (
        <div>
            <button onClick={handleAddButton}>Add Event</button>
            {showAddEventForm && (
                <AddEventComponent
                    newEvent={newEvent}
                    setNewEvent={setNewEvent}
                    handleAddEvent={handleAddEvent}
                    addStudentInfo={addStudentInfo}
                    studentName={studentName}
                    setStudentName={setStudentName}
                    topics={topics}
                    setTopics={setTopics}
                    subjects={subjects}
                    setSubjects={setSubjects}
                    hw={hw}
                    setHw={setHw}
                />
            )}
            {showEditEventForm && (
                <EditEventComponent
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    handleUpdateEvent={handleUpdateEvent}
                    handleCloseModal={handleCloseModal}
                />
            )}
            <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={handleEventClick}
            />
            {selectedEvent && !showEditEventForm && (
                <div>
                    <div className="event-modal">
                        <div className="event-modal-content">
                            <span className="close-modal" onClick={handleCloseModal}>
                                &times;
                            </span>
                            <>
                                <h2>Selected Event Details</h2>
                                <p>Title: {selectedEvent.title}</p>
                                <p>Start: {moment(selectedEvent.start).format('YYYY-MM-DD HH:mm')}</p>
                                <p>End: {moment(selectedEvent.end).format('YYYY-MM-DD HH:mm')}</p>
                                {selectedEvent.type === 'global' && <p>About: {selectedEvent.about}</p>}
                                {selectedEvent.type === 'session' && (
                                    <>
                                        <p>Tutor Name: {selectedEvent.tutorName}</p>
                                        <p>Student Info:</p>
                                        <div className="student-info-container">
                                            {Object.values(selectedEvent.studentInfo).map((student, index) => (
                                                <div key={index} className="student-info-box">
                                                    <p>Student Name: {student.studentName}</p>
                                                    <p>Topics: {student.topics.join(',')}</p>
                                                    <p>Subjects: {student.subjects.join(', ')}</p>
                                                    <p>Homework Assigned: {student.hw}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </div>
                    <button onClick={handleEditButton}>Edit Event</button>
                    <button onClick={handleRemoveButton}>Remove Event</button>
                </div>
            )}
        </div>
    );
};

export default ScheduleComponent;
