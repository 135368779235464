import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from '../../../src/config/firebase-config';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './styles.css';
import logo from '../../images/MZTutorsLogo.png';

export const Auth = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const isLoggedIn = Cookies.get('loggedIn');
    
    useEffect(() => {
        const isLoggedIn = Cookies.get('loggedIn');
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await setPersistence(auth, browserSessionPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setErrorMessage('Login successful!');
            Cookies.set('loggedIn', 'true', { expires: 1 });
            Cookies.set('userEmail', email);
            navigate('/dashboard');
        } catch (error) {
            setErrorMessage('Invalid credentials. Please try again.');
        }
    };
    
    return (
        <>
            <img className='Logo' style={{width: '100%', maxWidth: '200px'}} src={logo} alt="Website Logo" />
            <div className='login-page'>
                <p className='center'>Sign in with Email and Password</p>
                <form onSubmit={handleLogin}>
                    <label>Email:</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <label>Password:</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <button type="submit">Login</button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <p className='center'>For any query regarding your credentials please contact <a href='mailto:learn@mztutors.ca'>admin</a></p>
            </div>
        </>
    );
};

export default Auth;