import React, { useState, useEffect } from 'react';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';
import EditUserInfo from '../Admin/EditUserInfo'; // Ensure the path to EditUserInfo is correct
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../config/firebase-config'; // Assuming Firestore is initialized

const ChildrenProfileComponent = () => {
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const currentUser = useGetUserInfo();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userCollection = collection(db, 'Users');
                const userQuery = query(userCollection, where('email', '==', currentUser.email));
                const userSnapshot = await getDocs(userQuery);
                const user = userSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUserData(user);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser]);
    
    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleSave = () => {
        setIsEditing(false);
    };

    if (!userData) {
        return <p>Loading...</p>;
    }

    const childInfoArray = currentUser.childInfo ? Object.entries(currentUser.childInfo) : [];

    return (
        <div className="ChildrenProfile">
            <h2>Children's Profile</h2>
            {!isEditing ? (
                <div className="profile-info">
                <p><strong>Parent Name:</strong> {currentUser.name}</p>
                <p><strong>Email:</strong> {currentUser.email}</p>
                <p><strong>Phone Number:</strong> {currentUser.phoneNumber}</p>
                <p><strong>Role:</strong> {currentUser.role}</p>
                <p><strong>Child Info:</strong></p>
                {childInfoArray.length > 0 ? (
                    childInfoArray.map(([childId, child], index) => (
                        <div key={childId} className="child-info">
                            <h3>Child {index + 1}</h3>
                            <p><strong>Name:</strong> {child.name}</p>
                            <p><strong>Grade:</strong> {child.grade}</p>
                            <p><strong>Subjects:</strong></p>
                            <ul>
                                {child.subjects.map((subject, subjIndex) => (
                                    <li key={subjIndex}>{subject}</li>
                                ))}
                            </ul>
                        </div>
                    ))
                ) : (
                    <p>No child information available.</p>
                )}
                <button onClick={handleEdit}>Edit</button>
            </div>
            ): (
                <EditUserInfo
                    user={userData[0]}
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default ChildrenProfileComponent;
