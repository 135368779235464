// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEiksQjL_xwqfEHWm30bH0ag3aroaZ1vo",
  authDomain: "mztutors-76b5c.firebaseapp.com",
  projectId: "mztutors-76b5c",
  storageBucket: "mztutors-76b5c.appspot.com",
  messagingSenderId: "981192697706",
  appId: "1:981192697706:web:c95f93390b08f5f8086713"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
//export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);

//const analytics = getAnalytics(app);

//firebase login
//firebase init
//firebase deploy