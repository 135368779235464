import React, { useState } from 'react';
import moment from 'moment';

const EditEventComponent = ({ selectedEvent, setSelectedEvent, handleUpdateEvent, handleCloseModal }) => {
    const [newStudentName, setNewStudentName] = useState('');
    const [newStudentTopics, setNewStudentTopics] = useState('');
    const [newStudentSubjects, setNewStudentSubjects] = useState('');
    const [newStudentHw, setNewStudentHw] = useState('');
    const [showAddStudentFields, setShowAddStudentFields] = useState(false);

    const handleAddStudent = () => {
        if (newStudentName.trim() !== '' && newStudentTopics.trim() !== '' && newStudentSubjects.trim() !== '') {
            const updatedStudentInfo = selectedEvent.studentInfo ? { ...selectedEvent.studentInfo } : {};
            const newKey = `student${Object.keys(updatedStudentInfo).length + 1}`;
            updatedStudentInfo[newKey] = {
                studentName: newStudentName,
                topics: newStudentTopics.split(',').map(topic => topic.trim()),
                subjects: newStudentSubjects.split(',').map(subject => subject.trim()),
                hw: newStudentHw.trim()
            };
            setSelectedEvent({
                ...selectedEvent,
                studentInfo: updatedStudentInfo
            });
            setNewStudentName('');
            setNewStudentTopics('');
            setNewStudentSubjects('');
            setNewStudentHw('');
        }
    };

    const handleRemoveStudent = (key) => {
        const updatedStudents = { ...selectedEvent.studentInfo };
        delete updatedStudents[key];
        setSelectedEvent({
            ...selectedEvent,
            studentInfo: updatedStudents
        });
    };

    return (
        <div className="edit-event-form">
            <label>Event Title:</label>
            <input
                type="text"
                placeholder="Event Title"
                value={selectedEvent.title}
                onChange={e => setSelectedEvent({ ...selectedEvent, title: e.target.value })}
                required
            />
            <label>Start:</label>
            <input
                type="datetime-local"
                placeholder="Start Date and Time"
                value={moment(selectedEvent.start).format('YYYY-MM-DDTHH:mm')}
                onChange={e => setSelectedEvent({ ...selectedEvent, start: e.target.value })}
                required
            />
            <label>End:</label>
            <input
                type="datetime-local"
                placeholder="End Date and Time"
                value={moment(selectedEvent.end).format('YYYY-MM-DDTHH:mm')}
                onChange={e => setSelectedEvent({ ...selectedEvent, end: e.target.value })}
                required
            />
            <label>Type:</label>
            <select
                value={selectedEvent.type}
                onChange={e => setSelectedEvent({ ...selectedEvent, type: e.target.value })}
            >
                <option value="">Select Event Type</option>
                <option value="session">Session</option>
                <option value="global">Global</option>
            </select>
            {selectedEvent.type === 'global' && (
                <>
                    <label>About:</label>
                    <input
                        type="text"
                        placeholder="About (Global)"
                        value={selectedEvent.about}
                        onChange={e => setSelectedEvent({ ...selectedEvent, about: e.target.value })}
                        required
                    />
                </>
            )}
            {selectedEvent.type === 'session' && (
                <>
                    <label>Tutor Name:</label>
                    <input
                        type="text"
                        placeholder="Tutor Name"
                        value={selectedEvent.tutorName}
                        onChange={e => setSelectedEvent({ ...selectedEvent, tutorName: e.target.value })}
                        required
                    />
                    {selectedEvent.studentInfo ? (
                        Object.keys(selectedEvent.studentInfo).map((key) => (
                            <div key={key}>
                                <label>Student Name:</label>
                                <input
                                    type="text"
                                    placeholder="Student Name"
                                    value={selectedEvent.studentInfo[key].studentName}
                                    onChange={e => {
                                        const updatedStudents = { ...selectedEvent.studentInfo };
                                        updatedStudents[key].studentName = e.target.value;
                                        setSelectedEvent({
                                            ...selectedEvent,
                                            studentInfo: updatedStudents
                                        });
                                    }}
                                    required
                                />
                                <label>Topics:</label>
                                <input
                                    type="text"
                                    placeholder="Topics"
                                    value={selectedEvent.studentInfo[key].topics.join(',')}
                                    onChange={e => {
                                        const updatedStudents = { ...selectedEvent.studentInfo };
                                        updatedStudents[key].topics = e.target.value.split(',').map(topic => topic.trim());
                                        setSelectedEvent({
                                            ...selectedEvent,
                                            studentInfo: updatedStudents
                                        });
                                    }}
                                    required
                                />
                                <label>Subjects:</label>
                                <input
                                    type="text"
                                    placeholder="Subjects"
                                    value={selectedEvent.studentInfo[key].subjects.join(',')}
                                    onChange={e => {
                                        const updatedStudents = { ...selectedEvent.studentInfo };
                                        updatedStudents[key].subjects = e.target.value.split(',').map(subject => subject.trim());
                                        setSelectedEvent({
                                            ...selectedEvent,
                                            studentInfo: updatedStudents
                                        });
                                    }}
                                    required
                                />
                                <label>Homework Assigned:</label>
                                <input
                                    type="text"
                                    placeholder="Homework"
                                    value={selectedEvent.studentInfo[key].hw}
                                    onChange={e => {
                                        const updatedStudents = { ...selectedEvent.studentInfo };
                                        updatedStudents[key].hw = e.target.value;
                                        setSelectedEvent({
                                            ...selectedEvent,
                                            studentInfo: updatedStudents
                                        });
                                    }}
                                    required
                                />
                                <button onClick={() => handleRemoveStudent(key)}>Remove Student</button>
                            </div>
                        ))
                    ) : (
                        <p>No students available.</p>
                    )}
                    {showAddStudentFields && (
                        <div>
                            <input
                                type="text"
                                placeholder="Student Name"
                                value={newStudentName}
                                onChange={e => setNewStudentName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Topics (comma-separated)"
                                value={newStudentTopics}
                                onChange={e => setNewStudentTopics(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Subjects (comma-separated)"
                                value={newStudentSubjects}
                                onChange={e => setNewStudentSubjects(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Homework Assigned"
                                value={newStudentHw}
                                onChange={e => setNewStudentHw(e.target.value)}
                                required
                            />
                            <button onClick={handleAddStudent}>Add Student</button>
                        </div>
                    )}
                    {!showAddStudentFields && (
                        <button onClick={() => setShowAddStudentFields(true)}>Add Student</button>
                    )}
                </>
            )}
            <button onClick={handleUpdateEvent}>Update Event</button>
            <button onClick={handleCloseModal}>Close</button>
        </div>
    );
};

export default EditEventComponent;
