import { useState, useEffect } from 'react';
import { query, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase-config';

export const useGetEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getEvents = async () => {
      let unsubscribe;
      try {
        const eventsCollectionRef = collection(db, 'Events');
        const queryEvents = query(eventsCollectionRef);

        unsubscribe = onSnapshot(queryEvents, (snapshot) => {
          const eventsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));
          setEvents(eventsList);
        });
      } catch (error) {
        console.error('Error fetching events:', error);
        // Handle error (e.g., show error message, set default events, etc.)
      }
      return () => unsubscribe();
    };

    getEvents();
  }, []);

  return events;
};
