import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../config/firebase-config";

export const useAddEvents = () => {
  const eventsCollectionRef = collection(db, "Events");

  const addEvent = async (eventData) => {
    const {
      title,
      start,
      end,
      type,
      about,
      studentInfo,
      tutorName,
    } = eventData;

    // Convert 'start' and 'end' to Firestore Timestamps
    const startTimestamp = Timestamp.fromDate(new Date(start));
    const endTimestamp = Timestamp.fromDate(new Date(end));

    let eventDocument = {
      title,
      start: startTimestamp, // Use the Firestore Timestamp
      end: endTimestamp, // Use the Firestore Timestamp
      type,
      // Add other fields based on the event type
      ...(type === 'global' && { about }),
      ...(type === 'session' && { tutorName, studentInfo }),
    };

    await addDoc(eventsCollectionRef, eventDocument);
  };

  return { addEvent };
};
