import React, { useState, useEffect } from 'react';
import { useGetUserInfo } from '../../../../hooks/useGetUserInfo';
import EditUserInfo from '../Admin/EditUserInfo'; // Ensure the path to EditUserInfo is correct
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../config/firebase-config'; // Assuming you have initialized Firestore in your firebase.js file

const ProfileComponent = () => {
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const currentUser = useGetUserInfo();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const tutorsCollection = collection(db, 'Users');
                const tutorsQuery = query(tutorsCollection, where('email', '==', currentUser.email));
                const tutorsSnapshot = await getDocs(tutorsQuery);
                const tutor = tutorsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUserData(tutor);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser]);   

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleSave = () => {
        setIsEditing(false);
    };

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <div className="Profile">
            <h2>Profile</h2>
            {isEditing ? (
                <EditUserInfo user={userData[0]} onCancel={handleCancel} onSave={handleSave} />
            ) : (
                <div className="profile-info">
                    <p><strong>Name:</strong> {userData[0].name}</p>
                    <p><strong>Email:</strong> {userData[0].email}</p>
                    <p><strong>Subjects taught:</strong></p>
                    <ul>
                        {userData[0].subjects && userData[0].subjects.map((subject, index) => (
                            <li key={index}>{subject}</li>
                        ))}
                    </ul>
                    <button onClick={handleEdit}>Edit Profile</button>
                </div>
            )}
        </div>
    );
};

export default ProfileComponent;
